/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: var(--ion-font-family);
  //font-size: 22px;
}

.ionic-selectable-modal {
  ion-header {
    ion-toolbar {
      --background: #003d47;
      padding: 10px !important;
      --color: #ffffff;

      ion-title {
        --color: #ffffff;
        font-size: 1rem;
        letter-spacing: 0rem;
        text-align: center;
      }
    }

    ion-toolbar {
      ion-buttons {
        ion-button {
          --color: #ffffff;
        }
      }
    }
  }
}

.event-detail-container {
  display: none;
}

.table-bordered,
tr,
th,
thead,
td {
  border: 0px !important;
  color: white;
  font-size: 14px;
}

.monthview-selected {
  background: #2fb8a8 !important;
}

.box-shadow-1 {
  border: 2px solid #e7ebee;
  /*box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;*/
}

.transparent-modal .modal-wrapper {
  --background: rbga(44, 39, 45, 0.7);
}

.my-custom-class {
  .alert-wrapper {
    max-width: 400px;

    .alert-message{
      text-align: justify;
    }
  }
}

ion-content {
  padding: var(--safe-area-top) var(--safe-area-right) var(--safe-area-bottom) var(--safe-area-left);
}

ion-header {
  padding-top: var(--safe-area-top);
}

ion-footer {
  padding-bottom: var(--safe-area-bottom);
}